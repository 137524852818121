/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { TailSpin } from 'react-loader-spinner';
import { useRouter } from 'next/router';
import { AppContext } from '../../context';
import { CURRENT_CONFIG, GET_CURRENCIES } from '../../graphql/queries';
import { Currency, Setting } from '../../models';
import { Navbar, Footer } from '../common';
import LowerNavbar from '../common/LowerNavbar';
import { useNotify, useUser } from '../../hooks';
import { SIGN_OUT } from '../../graphql/mutations';

interface LayoutProps {
  children: React.ReactChild | Array<React.ReactChild>;
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  padding?: boolean;
  searchType?: 'search' | 'link' | 'none';
}

function Layout({
  children,
  search,
  setSearch,
  padding = true,
  searchType = 'search',
}: LayoutProps) {
  const router = useRouter();
  const notify = useNotify();
  const [user] = useUser();
  const [signOut] = useMutation(SIGN_OUT);
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [activeCurrency, setActiveCurrency] = React.useState<Currency>(null);
  const [settings, setSettings] = React.useState<Setting>(null);

  const { loading: settingsLoading, data: settingsData } = useQuery<{
    currentSetting: Setting;
  }>(CURRENT_CONFIG, {
    fetchPolicy: 'network-only',
  });

  const { data: currencyData, loading: currencyLoading } = useQuery<{
    currencies: Currency[];
  }>(GET_CURRENCIES, {
    variables: {
      filter: {
        active: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  // Settings hook
  React.useEffect(() => {
    if (!settingsLoading && settingsData?.currentSetting) {
      setSettings(settingsData?.currentSetting);
    }
  }, [settingsData, settingsLoading]);

  // Currency hook
  React.useEffect(() => {
    if (!currencyLoading && currencyData?.currencies) {
      setCurrencies(currencyData?.currencies);
      setActiveCurrency(currencyData?.currencies[0]);
    }
  }, [currencyData, currencyLoading]);

  const value = React.useMemo(
    () => ({
      settings,
      setSettings,
      currencies,
      setCurrencies,
      activeCurrency,
      setActiveCurrency,
      // plugins,
      // setPlugins,
    }),
    [activeCurrency, currencies, settings]
  );

  return (
    <AppContext.Provider value={value}>
      {settingsLoading || currencyLoading ? (
        <div className="min-h-screen w-full flex flex-row justify-center items-center">
          <TailSpin
            height={80}
            width={80}
            color="#353577"
            ariaLabel="loading"
          />
        </div>
      ) : (
        <div className="flex flex-col w-full min-h-screen bg-background-100">
          <div className="w-full bg-primary-300 border-0">
            <Navbar
              search={search}
              setSearch={setSearch}
              searchType={searchType}
            />
          </div>
          <div className="w-full bg-primary-300 border-0">
            <div
              className={`w-full bg-background-100 rounded-t-3xl md:rounded-3xl ${
                padding ? 'p-8 md:p-16' : 'p-0'
              }`}
            >
              {children}
            </div>
          </div>
          <LowerNavbar />
          <div className="w-full bg-primary-300 border-0">
            <div className="hidden md:flex px-6 md:px-8 lg:py-6 lg:px-14 mx-auto w-full z-10">
              <Footer />
            </div>
          </div>
        </div>
      )}
    </AppContext.Provider>
  );
}

export default Layout;
