import { Product, ShopCart } from '../models';

const checkAmountInShopCart = (shopCart: ShopCart, product: Product) => {
  const thisProduct = shopCart?.items?.filter(
    (item) => item?.product?._id === product?._id
  );
  const amountInShopCart = thisProduct.reduce(
    (acc, item) => acc + (item?.quantity ?? 0),
    0
  );
  return (product?.limitPerOrder ?? 0) - amountInShopCart;
};

export default checkAmountInShopCart;
