import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BagIcon from '../icons/BagIcon';
import HomeIcon from '../icons/HomeIcon';
import UserIcon from '../icons/UserIcon';
import ShoppingCartIcon from '../icons/ShoppingCartIcon';
import { useUser } from '../../hooks';
import LoginIcon from '../icons/LoginIcon';

function LowerNavbar() {
  const [user] = useUser();
  const router = useRouter();
  return (
    <div className="w-full px-5 flex flex-col md:hidden sticky bottom-10 z-10">
      {router.asPath === '/shopping-cart' || !user ? null : (
        <div className="w-full flex justify-end items-end">
          <div className="w-16 h-16 flex justify-center items-center bg-primary-300 hover:bg-primary-200 rounded-full">
            <Link href="/shopping-cart">
              <a>
                <ShoppingCartIcon className="w-7 h-7 text-font-white" />
              </a>
            </Link>
          </div>
        </div>
      )}

      <div className="mt-5 w-full h-14 rounded-3xl bg-background-100 shadow-lg shadow-neutral-300/50 border-xs border-neutral-300 flex flex-row justify-evenly items-center">
        <Link href="/">
          <a>
            <HomeIcon
              className={`w-6 h-6 ${
                router.asPath === '/'
                  ? 'text-secondary-200'
                  : 'text-primary-50 hover:text-primary-100'
              }`}
            />
          </a>
        </Link>
        {user ? (
          <>
            <Link href="/my-orders">
              <a>
                <BagIcon
                  className={`w-6 h-6 ${
                    router.asPath === '/my-orders'
                      ? 'text-secondary-200'
                      : 'text-primary-50 hover:text-primary-100'
                  }`}
                />
              </a>
            </Link>
            <Link href="/profile">
              <a>
                <UserIcon
                  className={`w-6 h-6 ${
                    router.asPath === '/profile'
                      ? 'text-secondary-200'
                      : 'text-primary-50 hover:text-primary-100'
                  }`}
                />
              </a>
            </Link>
          </>
        ) : (
          <Link href="/login">
            <a>
              <LoginIcon
                className={`w-6 h-6 ${
                  router.asPath === '/profile'
                    ? 'text-secondary-200'
                    : 'text-primary-50 hover:text-primary-100'
                }`}
              />
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}

export default LowerNavbar;
