/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNotify } from '../../hooks';
import formatMoney from '../../lib/formatMoney';
import { Option } from '../../models';
import CheckIcon from '../icons/CheckIcon';

interface OrderProductOptions {
  _id?: string;
  type?: 'fixed' | 'range' | 'optional';
  title: string;
  value: string;
  price: number;
  groupId?: string;
}
interface OptionSelectProps {
  options: Option[];
  formatOptions: OrderProductOptions[];
  setFormatOptions: React.Dispatch<React.SetStateAction<OrderProductOptions[]>>;
}

function OptionSelect({
  options,
  formatOptions,
  setFormatOptions,
}: OptionSelectProps) {
  const notify = useNotify();
  const addOption = (
    option: Option,
    item: { _id: string; name: string; price?: number; active?: boolean }
  ) => {
    if (option?.type === 'optional') {
      setFormatOptions([
        ...(formatOptions?.filter((_option) => _option?.type !== 'optional') ??
          []),
        {
          _id: item?._id,
          type: option?.type,
          title: option?.title,
          value: item?.name,
          price: item?.price,
          groupId: option?._id,
        },
      ]);
    } else if (
      formatOptions?.filter((_option) => _option?.groupId === option?._id)
        ?.length === option?.maxChoices
    ) {
      notify(
        'Has alcanzado el límite de opciones a seleccionar del producto',
        'info'
      );
    } else {
      setFormatOptions([
        ...formatOptions,
        {
          _id: item?._id,
          type: option?.type,
          title: option?.title,
          value: item?.name,
          price: item?.price,
          groupId: option?._id,
        },
      ]);
    }
    // switch (option?.type) {
    //   case 'fixed':
    //     setFormatOptions([
    //       ...(formatOptions?.filter((_option) => _option?.type !== 'fixed') ??
    //         []),
    //       {
    //         _id: item?._id,
    //         type: option?.type,
    //         title: option?.title,
    //         value: item?.name,
    //         price: item?.price,
    //         groupId: option?._id,
    //       },
    //     ]);
    //     break;
    //   case 'range':
    //     if (
    //       formatOptions?.filter(
    //         (_option) =>
    //           _option?.type === 'range' && _option?.groupId === option?._id
    //       )?.length === option?.maxChoices
    //     ) {
    //       notify(
    //         'Has alcanzado el límite de opciones a seleccionar del producto',
    //         'info'
    //       );
    //     } else {
    //       setFormatOptions([
    //         ...formatOptions,
    //         {
    //           _id: item?._id,
    //           type: option?.type,
    //           title: option?.title,
    //           value: item?.name,
    //           price: item?.price,
    //           groupId: option?._id,
    //         },
    //       ]);
    //     }
    //     break;
    //   case 'optional':
    //     setFormatOptions([
    //       ...(formatOptions?.filter(
    //         (_option) => _option?.type !== 'optional'
    //       ) ?? []),
    //       {
    //         _id: item?._id,
    //         type: option?.type,
    //         title: option?.title,
    //         value: item?.name,
    //         price: item?.price,
    //         groupId: option?._id,
    //       },
    //     ]);
    //     break;
    //   default:
    //     break;
    // }
  };

  return (
    <div className="flex flex-col w-full mt-5">
      {options?.map((option) => (
        <div className="w-full flex flex-col mb-5" key={option?._id}>
          <div className="w-full flex flex-col gap-1 items-start justify-start pb-1 mb-2 border-b border-neutral-300">
            <p className="w-full text-base md:text-lg lg:text-2xl text-primary-300 font-semibold text-left font-century">
              {option?.title}
            </p>
            <p className="text-neutral-500 font-montserrat text-xs">
              {option?.type === 'optional'
                ? '(Opcional)'
                : option?.type === 'fixed'
                ? `(Seleccione ${option?.maxChoices} ${
                    option?.maxChoices > 1 ? 'opciones' : 'opción'
                  })`
                : `(Seleccione entre ${option?.minChoices} a ${option?.maxChoices} opciones)`}
            </p>
          </div>
          <div className="w-full flex flex-col">
            {option?.items?.map((item) => (
              <div
                className="w-full flex flex-row text-avenir justify-between my-1"
                key={item?._id}
              >
                <div className="flex flex-row">
                  <button
                    type="button"
                    className="flex flex-row items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        formatOptions
                          ?.map((_option) => _option?._id)
                          ?.includes(item?._id)
                      ) {
                        setFormatOptions(
                          formatOptions?.filter(
                            (_option) => _option?._id !== item?._id
                          )
                        );
                      } else {
                        addOption(option, item);
                      }
                    }}
                  >
                    {formatOptions
                      ?.map((_option) => _option?._id)
                      ?.includes(item?._id) ? (
                      <p
                        className={`w-5 h-5 border mr-2 bg-primary-300 flex justify-center items-center ${
                          option?.maxChoices > 1 ? 'rounded-md' : 'rounded-full'
                        }`}
                      >
                        <CheckIcon className="w-4/5 text-font-white" />
                      </p>
                    ) : (
                      <p
                        className={`w-5 h-5 border mr-2 bg-transparent ${
                          option?.maxChoices > 1 ? 'rounded-md' : 'rounded-full'
                        }`}
                      />
                    )}
                    <p className="text-font-black text-sm md:text-base lg:text-lg">
                      {item?.name}
                    </p>
                  </button>
                </div>
                {formatMoney(item?.price) !== '0.00' ? (
                  <p className="font-semibold text-secondary-300">
                    + $ {formatMoney(item?.price)}
                  </p>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default OptionSelect;
